<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {required} from "vuelidate/lib/validators";
import {paginationHelper} from "@/helpers/pagination-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import allCountries from "@/helpers/all-countries";
import Swal from "sweetalert2";

import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";

/**
 * Package Senders Component
 */
export default {

  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },

  components: {
    CustomCardSubtitle,

    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,

        items: []
      },

      inputPage: "",

      packageSender: {
        displayName: "",
        companyName: "",
        street: "",
        buildingNumber: "",
        city: "",
        postCode: "",
        country: "PL",
        phoneNumber: "",
        firstName: "",
        lastName: "",
        email: ""
      },

      showModal: false
    };
  },

  validations: {
    packageSender: {
      displayName: {required},
      companyName: {required},
      street: {required},
      buildingNumber: {required},
      city: {required},
      postCode: {required},
      country: {required},
      phoneNumber: {required},
      firstName: {required},
      lastName: {required},
      email: {required}
    }
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('core.package-senders.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        {key: "displayName", label: 'Nazwa własna'},
        {key: "companyName", label: 'Nazwa firmy'},
        {key: "removed", slot: true, label: 'Status'},
        {key: "street", label: 'Ulica'},
        {key: "buildingNumber", label: 'Numer budynku'},
        {key: "city", label: 'Miasto'},
        {key: "postCode", label: 'Kod pocztowy'},
        {key: "country", label: 'Kraj'},
        {key: "phoneNumber", label: 'Numer telefonu'},
        {key: "firstName", label: 'Imię'},
        {key: "lastName", label: 'Nazwisko'},
        {key: "email", label: 'Email'},
        {key: "action", slot: true, label: this.$t('table.actions')}
      ]
    },

    getAllCountries() {
      return allCountries;
    },

    async loadPackageSenders() {
      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      const result = await axios.get(`/logistics/package-sender/list/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "page": page,
          "size": this.table.perPage
        }
      });

      const data = result.data;

      this.table.items = data.packageSenders
      this.table.totalRows = data.count;
      this.table.rows = data.count;

      if (this.mounted) {
        this.$refs.table.refresh()
      }

      return this.table.items;
    },

    createOrEditPackageSender() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const json = JSON.stringify(this.packageSender);

      axios.post(`/logistics/package-sender`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast(this.$t(this.packageSender.id ? "core.package-senders.edit.success" : "core.package-senders.create.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        if (this.packageSender.id) {
          for (const index in this.table.items) {
            if (this.table.items[index].id === this.packageSender.id) {
              this.table.items[index] = this.packageSender;
              break;
            }
          }

          this.$refs.table.refresh();
        } else {
          this.table.items.push(this.packageSender)
          this.$refs.table.refresh();
        }

        this.hideModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    changeRemovedStatus(packageSender) {
      this.submitted = true;

      const object = Object.assign({}, packageSender)
      object.removed = !object.removed

      const json = JSON.stringify(object);

      axios.post(`/logistics/package-sender`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        for (const index in this.table.items) {
          if (this.table.items[index].id === object.id) {
            this.table.items[index].removed = object.removed;
            break;
          }
        }

        this.$refs.table.refresh()
        Swal.fire("Sukces!", object.removed ? "Zmieniono status na wyłączony" : "Zmieniono status na włączony", "success");
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    hideModal() {
      this.submitted = false;
      this.showModal = false;
      this.packageSender = {
        displayName: "",
        companyName: "",
        street: "",
        buildingNumber: "",
        city: "",
        postCode: "",
        country: "PL",
        phoneNumber: "",
        firstName: "",
        lastName: "",
        email: ""
      };
    },

    editPackageSender(packageSender) {
      this.packageSender = Object.assign({}, packageSender);
      this.showModal = true
    }

  },

  async created() {
    try {
      await this.loadPackageSenders()
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('core.package-senders.title')" :items="getItems()"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <a class="btn btn-success" @click="showModal = true">
                  <i class="mdi mdi-plus mr-2"></i> {{ $t('core.package-senders.create.button') }}
                </a>
              </div>
            </div>

            <template v-if="table.items">
              <ecat-table
                  ref="table"
                  :table="table"
                  :fields="getFields()"
                  :items="loadPackageSenders"
                  :pagination-top="true"

              >
                <template v-slot:removed="{ item }">
                <span :class="item.removed ? 'badge badge-soft-danger' : 'badge badge-soft-success'"
                      class="font-size-11"> {{ $t(item.removed ? 'table.inactive' : 'table.active') }}</span>
                </template>

                <template v-slot:action="{ item }">
                  <a @click="changeRemovedStatus(item)" class="clickable-element mr-3 text-primary"><i
                      class="font-size-18" :class="item.removed ? 'fa fa-toggle-off' : 'fa fa-toggle-on'"></i></a>
                  <a @click="editPackageSender(item)" class="clickable-element mr-3 text-primary"><i
                      class="mdi mdi-pencil font-size-18"></i></a>
                </template>
              </ecat-table>
            </template>
            <template v-else>
              <div class="text-center py-3">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
        id="modal-1"
        :scrollable="true"
        v-model="showModal"
        :title="$t(this.packageSender.id ? 'core.package-senders.edit.title' : 'core.package-senders.create.title')"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">
      <form>

        <div class="form-group">
          <label>Nazwa własna</label>
          <input v-model="packageSender.displayName"
                 :class="{ 'is-invalid': submitted && $v.packageSender.displayName.$error }" class="form-control"
                 type="text"/>
          <div v-if="submitted && !$v.packageSender.displayName.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <label>Nazwa firmy</label>
          <input v-model="packageSender.companyName"
                 :class="{ 'is-invalid': submitted && $v.packageSender.companyName.$error }" class="form-control"
                 type="text"/>
          <div v-if="submitted && !$v.packageSender.companyName.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <label>Ulica</label>
          <input v-model="packageSender.street" :class="{ 'is-invalid': submitted && $v.packageSender.street.$error }"
                 class="form-control" type="text"/>
          <div v-if="submitted && !$v.packageSender.street.required" class="invalid-feedback">{{
              $t('message.required')
            }}
          </div>
        </div>

        <div class="form-group">
          <label>Numer budynku</label>
          <input v-model="packageSender.buildingNumber"
                 :class="{ 'is-invalid': submitted && $v.packageSender.buildingNumber.$error }" class="form-control"
                 type="text"/>
          <div v-if="submitted && !$v.packageSender.buildingNumber.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <label>Miasto</label>
          <input v-model="packageSender.city" :class="{ 'is-invalid': submitted && $v.packageSender.city.$error }"
                 class="form-control" type="text"/>
          <div v-if="submitted && !$v.packageSender.city.required" class="invalid-feedback">{{
              $t('message.required')
            }}
          </div>
        </div>

        <div class="form-group">
          <label>Kod pocztowy</label>
          <input v-model="packageSender.postCode"
                 :class="{ 'is-invalid': submitted && $v.packageSender.postCode.$error }" class="form-control"
                 type="text"/>
          <div v-if="submitted && !$v.packageSender.postCode.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <label>Kraj</label>
          <select v-model="packageSender.country" class="custom-select"
                  :class="{ 'is-invalid': submitted && $v.packageSender.country.$error }">
            <option v-for="(item, index) in getAllCountries()" :key="index" :value="`${item.iso2.toUpperCase()}`">
              {{ item.name }} - {{ item.iso2.toUpperCase() }}
            </option>
          </select>
          <div v-if="submitted && !$v.packageSender.country.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <label>Numer telefonu</label>
          <input v-model="packageSender.phoneNumber"
                 :class="{ 'is-invalid': submitted && $v.packageSender.phoneNumber.$error }" class="form-control"
                 type="text"/>
          <div v-if="submitted && !$v.packageSender.phoneNumber.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <label>Imię</label>
          <input v-model="packageSender.firstName"
                 :class="{ 'is-invalid': submitted && $v.packageSender.firstName.$error }" class="form-control"
                 type="text"/>
          <div v-if="submitted && !$v.packageSender.firstName.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <label>Nazwisko</label>
          <input v-model="packageSender.lastName"
                 :class="{ 'is-invalid': submitted && $v.packageSender.lastName.$error }" class="form-control"
                 type="text"/>
          <div v-if="submitted && !$v.packageSender.lastName.required" class="invalid-feedback">
            {{ $t('message.required') }}
          </div>
        </div>

        <div class="form-group">
          <label>Email</label>
          <input v-model="packageSender.email" :class="{ 'is-invalid': submitted && $v.packageSender.email.$error }"
                 class="form-control" type="text"/>
          <div v-if="submitted && !$v.packageSender.email.required" class="invalid-feedback">{{
              $t('message.required')
            }}
          </div>
        </div>

        <div class="text-right">
          <b-button @click="createOrEditPackageSender" variant="success">
            {{ $t(this.packageSender.id ? 'core.package-senders.edit.button' : 'core.package-senders.create.button') }}
          </b-button>
          <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

  </Layout>
</template>